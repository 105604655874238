


































import {
    defineComponent,
    reactive,
    toRefs,
    ref,
    watch,
} from "@vue/composition-api"
import { postSpaceObject } from "@/services/SpaceObjectsService"
import { successDialog, warningDialog } from "@/services/DialogService"

export default defineComponent({
    setup(_, { emit }) {
        const form = ref(null as null | any)

        const state = reactive({
            dialog: false,
            number: "",
            name: "",
            parentId: null as string | null,
        })

        watch(
            () => state.dialog,
            (value) => {
                if (value) return
                form.value?.resetValidation()
                state.number = ""
                state.name = ""
            }
        )

        const rules = {
            required: (value: string) => !!value || "必填",
        }

        async function save() {
            if (!form.value!.validate()) return
            try {
                await postSpaceObject({
                    number: state.number,
                    name: state.name,
                    type: "SPACE",
                })
                await successDialog("建立成功")
                state.dialog = false
                emit("save")
            } catch (error) {
                warningDialog("建立失敗")
            }
        }
        return {
            form,
            ...toRefs(state),
            rules,
            save,
        }
    },
})
